'use client'

import { Fragment } from 'react'
import { Card } from '@vinted/web-ui'

import { BundleEntryBanner } from '@marketplace-web/domain/items-bundle'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useSession } from '@marketplace-web/shared/session'
import { isDateInTheFuture } from '@marketplace-web/shared/utils'

import { isBundlingAvailable } from '_libs/utils/bundle'
import { transformUserBundleDiscountDto } from 'data/transformers/bundle-discount'

import { UserDto } from 'types/dtos'

type Props = {
  user: UserDto | null | undefined
  totalItemsCount: number
  userId: number
  renderCountDownBanner: () => JSX.Element | null
}

const ProfileVisitorBanners = ({ user, totalItemsCount, userId, renderCountDownBanner }: Props) => {
  const breakpoints = useBreakpoint()

  const isCurrentUserAuthenticated = !!useSession().user

  const { bundle_discount: bundleDiscount, closet_countdown_end_date: closetCountdownEndDate } =
    user || {}

  if (closetCountdownEndDate && isDateInTheFuture(closetCountdownEndDate))
    return renderCountDownBanner()

  const showBundles = isBundlingAvailable({
    canBundle: !!user?.can_bundle,
    totalItemCount: totalItemsCount,
    isCurrentUserAuthenticated,
    closetCountdownEndDate,
    hasHateFromAnySide: !!user?.is_hated || !!user?.hates_you,
    isViewingOwnProfile: false,
  })

  if (!showBundles) return null

  const transformedBundleDiscounts = bundleDiscount
    ? transformUserBundleDiscountDto(bundleDiscount)
    : null

  const Wrapper = breakpoints.tabletsUp ? Card : Fragment

  return (
    <Wrapper>
      <BundleEntryBanner
        itemUserId={userId}
        bundleDiscount={transformedBundleDiscounts}
        testId="profile-bundle-banner"
      />
    </Wrapper>
  )
}

export default ProfileVisitorBanners
