'use client'

import { ContentLoader } from '@marketplace-web/shared/ui-helpers'
import { useAbTest, useTrackAbTest } from '@marketplace-web/shared/ab-tests'

import { AbTestVariant } from 'constants/abtest'

import { type SellerInsightsType } from '../../constants/insights'
import useGetPhotoRecommendations from '../../hooks/useGetPhotoRecommendations'
import SellerInsightsPhotoRecommendations from './SellerInsightsPhotoRecommendations/SellerInsightsPhotoRecommendations'
import SellerInsightsBlocks from './SellerInsightsBlocks'
import SellerInsightsTipsBanner from './SellerInsightsTipsBanner'
import SustainabilityImpact from './SustainabilityImpact'

type Props = {
  sellerInsights: SellerInsightsType
  isSellerInsightsLoading: boolean
}

const SellerInsights = ({ sellerInsights, isSellerInsightsLoading }: Props) => {
  const { photoRecommendations, isPhotoRecommendationsLoading } = useGetPhotoRecommendations()

  const sellerInsightsElectronicsTipsBannerAbTest = useAbTest('seller_tip_banner_electronics')
  const isSellerInsightsElectronicsTipsBannerEnabled =
    sellerInsightsElectronicsTipsBannerAbTest &&
    sellerInsightsElectronicsTipsBannerAbTest.variant !== AbTestVariant.Off
  useTrackAbTest(sellerInsightsElectronicsTipsBannerAbTest)

  const sustainabilityImpactAbTest = useAbTest('seller_insights_sustainability')
  const isSustainabilityImpactAbTestEnabled =
    sustainabilityImpactAbTest && sustainabilityImpactAbTest.variant !== AbTestVariant.Off
  useTrackAbTest(sustainabilityImpactAbTest)

  if (isSellerInsightsLoading || isPhotoRecommendationsLoading)
    return <ContentLoader testId="seller-insights-loader" />

  if (!sellerInsights?.isVisible) return null

  return (
    <section>
      {isSustainabilityImpactAbTestEnabled && <SustainabilityImpact />}
      <div className="seller-blocks-container">
        {isSellerInsightsElectronicsTipsBannerEnabled && <SellerInsightsTipsBanner />}
        {sellerInsights?.isVisible && <SellerInsightsBlocks sellerInsights={sellerInsights} />}
      </div>
      {photoRecommendations && <SellerInsightsPhotoRecommendations {...photoRecommendations} />}
    </section>
  )
}

export default SellerInsights
