import { useEffect } from 'react'

import { removeParamsFromQuery, urlWithParams } from '_libs/utils/url'
import { useLocation } from '@marketplace-web/shared/browser'

import { PromoAfterItemUpload } from '../containers/PromoAfterItemUploadContext'

const usePromoShown = (currentPromotion: PromoAfterItemUpload | null) => {
  const { replaceHistoryState, relativeUrl, urlQuery, searchParams } = useLocation()

  useEffect(() => {
    if (!currentPromotion || currentPromotion === PromoAfterItemUpload.Empty) {
      const urlWithoutParam = removeParamsFromQuery(relativeUrl, urlQuery, ['promo_shown'])
      replaceHistoryState(urlWithoutParam)

      return
    }

    const updatedUrl = urlWithParams(relativeUrl, {
      ...searchParams,
      promo_shown: true,
    })

    replaceHistoryState(updatedUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPromotion])
}

export default usePromoShown
