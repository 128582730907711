'use client'

import { useEffect, useState } from 'react'

import ListPromotionModal from 'components/ListPromotionModal'

import ItemUploadFeedback from './ItemUploadFeedback'
import ItemUploadFeedbackNotification from './ItemUploadFeedbackNotification'
import usePromoAfterItemUploadContext from '../../../hooks/usePromoAfterItemUploadContext'
import { PromoAfterItemUpload } from '../../../containers/PromoAfterItemUploadContext'

const PromosAfterItemUpload = () => {
  const [isFeedbackNotificationOpen, setIsFeedbackNotificationOpen] = useState(false)
  const { currentPromotion, setNextPromotion } = usePromoAfterItemUploadContext()

  useEffect(() => {
    setNextPromotion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNotificationShow = () => {
    setIsFeedbackNotificationOpen(true)
  }

  const closeNotification = () => {
    setIsFeedbackNotificationOpen(false)
  }

  return (
    <>
      {currentPromotion === PromoAfterItemUpload.ListAnotherItem && <ListPromotionModal />}
      {currentPromotion === PromoAfterItemUpload.ItemUploadFeedback && (
        <ItemUploadFeedback onClose={handleNotificationShow} />
      )}
      {isFeedbackNotificationOpen && (
        <ItemUploadFeedbackNotification handleClose={closeNotification} />
      )}
    </>
  )
}

export default PromosAfterItemUpload
