'use client'

import { useRef, useState } from 'react'
import { Button, Cell, Icon, Image, Rating, Spacer } from '@vinted/web-ui'
import { noop } from 'lodash'
import { HorizontalDots24, Pencil24 } from '@vinted/monochrome-icons'
import classNames from 'classnames'

import { SellerBadge, SellerBadgeType } from '@marketplace-web/domain/seller-engagement-tools'
import { useAbTest } from '@marketplace-web/shared/ab-tests'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import {
  navigateToPage,
  reloadPage,
  scrollToElementById,
  useLocation,
} from '@marketplace-web/shared/browser'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useSession } from '@marketplace-web/shared/session'
import { SeparatedList, UserTitle } from '@marketplace-web/shared/ui-helpers'
import { isDateInTheFuture } from '@marketplace-web/shared/utils'

import { EMPTY_USER_IMAGE_NAME } from 'constants/images'
import { urlWithParams } from '_libs/utils/url'
import DropdownMenu, { DropdownActions } from 'components/DropdownMenu'
import FollowButton from 'components/FollowButton'
import UserBlockModal from 'components/UserBlockModal'

import { NEW_ADMIN_ALERT_URL, PROFILE_SETTINGS_URL } from 'constants/routes'
import { NEW_MESSAGE_URL } from 'constants/routes/inbox'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { blockUser, unblockUser } from 'data/api'
import { calculateRating } from 'data/utils/user'
import useAsset from 'hooks/useAsset'
import { SellerBadgeDto, UserDto } from 'types/dtos'
import { AlertType } from 'constants/admin'
import { clickEvent, followUserEvent } from '_libs/common/event-tracker/events'

import { AbTestVariant } from 'constants/abtest'

import UnauthenticatedReportModal from 'pages/Profile/UnauthenticatedReportModal'

import UserInfo from './UserInfo'
import UserDescription from './UserDescription'
import UserStatuses from './UserStatuses'

type Props = {
  user: UserDto
  sellerBadgesList: Array<Omit<SellerBadgeDto, 'progress'>> | undefined
  viewingSelf: boolean
  showActions: boolean
  showSupportFunctionality: boolean
  canSendTemplates: boolean
  screen: Screen
  isShadowBanned?: boolean
  isPermanentlyBanned?: boolean
  isTemporarilyBanned?: boolean
  isCompromised?: boolean
  isListingRestricted?: boolean
  isContentTranslationEnabled: boolean
  onTogglePoliciesModal?: () => void
  onRatingClick?: () => void
}

const InfoHeader = ({
  user,
  sellerBadgesList,
  viewingSelf,
  showActions,
  showSupportFunctionality,
  canSendTemplates,
  screen,
  isShadowBanned,
  isPermanentlyBanned,
  isTemporarilyBanned,
  isCompromised,
  isListingRestricted,
  isContentTranslationEnabled,
  onTogglePoliciesModal = noop,
  onRatingClick = noop,
}: Props) => {
  const breakpoints = useBreakpoint()
  const { relativeUrl } = useLocation()
  const translate = useTranslate('user')
  const { track } = useTracking()
  const asset = useAsset('assets/no-photo')
  const { id: currentUserId } = useSession().user || {}

  const isFastReplierBadgeEnabled = useFeatureSwitch('web_fast_replier_badge')

  const fastReplierBadgeAbTest = useAbTest('fast_replier_badge_v2')
  const isRemoveDirectUserMessagingAbTestEnabled =
    useAbTest('remove_direct_user_messaging')?.variant === AbTestVariant.On

  const showFastReplierBadge = isFastReplierBadgeEnabled && fastReplierBadgeAbTest?.variant === 'on'

  const isActionsVisible = showActions && !user.is_account_banned

  const isProfileBlockingImprovementsEnabled = useFeatureSwitch('profile_blocking_improvements')

  const isProfileReportingModalEnabled = useFeatureSwitch('ntd_form_user')

  const [isBlockModalVisible, setIsBlockModalVisible] = useState(false)
  const [isReportModalVisible, setIsReportModalVisible] = useState(false)
  const isFollowTracked = useRef(false)
  const isMessageTracked = useRef(false)

  if (breakpoints.phones) return null

  const photoUrl = user.photo?.url || asset(EMPTY_USER_IMAGE_NAME)
  const rating = calculateRating(user.feedback_reputation)

  const toggleBlock = async () => {
    if (!currentUserId) return

    const toggleFunction = user.is_hated ? unblockUser : blockUser

    await toggleFunction({ currentUserId, blockedUserId: user.id })
    reloadPage()
  }

  const trackFollowToggle = ({ isFollowEvent }: { isFollowEvent: boolean }) => {
    track(followUserEvent({ userId: user.id, isFollowEvent }))

    if (isFollowTracked.current) return

    isFollowTracked.current = true
    track(clickEvent({ target: ClickableElement.Follow, screen: Screen.UserProfile }))
  }

  const trackMessageClick = () => {
    if (isMessageTracked.current) return

    isMessageTracked.current = true
    track(clickEvent({ target: ClickableElement.ProfileMessage, screen: Screen.UserProfile }))
  }

  function handleBlockCancel() {
    setIsBlockModalVisible(false)
  }

  function handleModalBlockButtonClick() {
    setIsBlockModalVisible(false)
    reloadPage()
  }

  function handleBlockClick() {
    if (isProfileBlockingImprovementsEnabled) {
      setIsBlockModalVisible(true)

      return
    }

    toggleBlock()
  }

  function handleRatingsClick() {
    onRatingClick()
    scrollToElementById('tabs')
  }

  function handleReportClick() {
    if (currentUserId || !isProfileReportingModalEnabled) {
      const url = urlWithParams(NEW_ADMIN_ALERT_URL, {
        ref_id: user.id,
        ref_type: AlertType.Member,
        ref_url: relativeUrl,
      })

      navigateToPage(url)

      return
    }

    setIsReportModalVisible(true)
  }

  function handleReportModalClose() {
    setIsReportModalVisible(false)
  }

  function renderEditProfileAction() {
    if (!viewingSelf) return null

    return (
      <Button
        icon={<Icon name={Pencil24} color={Icon.Color.GreyscaleLevel2} />}
        url={PROFILE_SETTINGS_URL}
        text={translate('action.edit_profile')}
        theme="muted"
        size={Button.Size.Medium}
        inline
      />
    )
  }

  function renderMessageAction() {
    if (
      viewingSelf ||
      !isActionsVisible ||
      !user.allow_direct_messaging ||
      isRemoveDirectUserMessagingAbTestEnabled
    )
      return null

    return (
      <Button
        text={translate('action.write_message')}
        onClick={trackMessageClick}
        url={urlWithParams(NEW_MESSAGE_URL, {
          receiver_id: user.id,
          ref_url: relativeUrl,
        })}
        size={Button.Size.Medium}
        testId="info-header-message-button"
        inline
      />
    )
  }

  function renderFollowAction() {
    if (viewingSelf || !isActionsVisible) return null
    if (isDateInTheFuture(user.closet_countdown_end_date)) return null

    return (
      <div>
        <FollowButton
          onFollowToggle={trackFollowToggle}
          userId={user.id}
          isActive={user.is_favourite}
          size={Button.Size.Medium}
          testId="profile-info-follow-button"
        />
      </div>
    )
  }

  function renderExtraActions() {
    if (viewingSelf && !showSupportFunctionality) return null

    const actions: Array<DropdownActions> = [
      {
        id: 'report',
        text: translate('action.report'),
        onClick: handleReportClick,
      },
    ]

    if (currentUserId) {
      actions.push({
        id: user.is_hated ? 'unblock' : 'block',
        text: translate(user.is_hated ? 'action.unblock' : 'action.block'),
        onClick: handleBlockClick,
      })
    }

    const dropdownButtonProps = {
      styling: Button.Styling.Flat,
      icon: (
        <Icon
          testId="actions-menu-button"
          name={HorizontalDots24}
          color={Icon.Color.GreyscaleLevel2}
          title={translate('a11y.show_more')}
        />
      ),
      size: Button.Size.Medium,
    }

    return (
      <div>
        <DropdownMenu actions={actions} buttonProps={dropdownButtonProps} />
      </div>
    )
  }

  function renderMainActions() {
    return (
      <div className="u-flexbox">
        <SeparatedList
          separator={<Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Large} />}
        >
          {renderEditProfileAction()}
          {renderMessageAction()}
          {renderFollowAction()}
        </SeparatedList>
      </div>
    )
  }

  function renderAllActions() {
    return (
      <div className="u-flexbox">
        <SeparatedList
          separator={<Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Large} />}
        >
          {renderEditProfileAction()}
          {renderMessageAction()}
          {renderFollowAction()}
          {renderExtraActions()}
        </SeparatedList>
      </div>
    )
  }

  function renderHeaderContent() {
    return (
      <>
        <button type="button" onClick={handleRatingsClick} data-testid="rating-button">
          <Rating
            aria={{
              'aria-label': (rating && translate('a11y.rating', { rating, max_rating: 5 })) || '',
            }}
            value={rating}
            text={translate(
              'feedback.reviews',
              { count: user.feedback_count },
              { count: user.feedback_count },
            )}
            emptyStateText={translate('feedback.no_reviews')}
          />
        </button>
        {breakpoints.tablets && (
          <>
            <Spacer />
            {renderMainActions()}
          </>
        )}
      </>
    )
  }

  function renderHeaderSuffix() {
    return breakpoints.desktops ? renderAllActions() : renderExtraActions()
  }

  function renderUserBlockModal() {
    return (
      <UserBlockModal
        username={user.login}
        isUserBlocked={user.is_hated}
        userId={user.id}
        isVisible={isBlockModalVisible}
        onCancel={handleBlockCancel}
        onConfirm={handleModalBlockButtonClick}
      />
    )
  }

  const renderSellerBadges = () => {
    if (!user) return null

    if (!showFastReplierBadge) {
      if (viewingSelf) return null

      const { seller_badges } = user

      if (!seller_badges?.length) return null

      const activeListerBadge = seller_badges.find(
        badge => badge.type === SellerBadgeType.ActiveLister,
      )

      return activeListerBadge ? (
        <div className="u-flexbox">
          <Spacer size={Spacer.Size.Large} orientation={Spacer.Orientation.Vertical} />
          <SellerBadge type={activeListerBadge.type} />
        </div>
      ) : null
    }

    if (!sellerBadgesList?.length) return null

    const containerClass = classNames('u-flexbox', {
      'u-flex-direction-column': breakpoints.tablets,
    })

    const renderSeparator = () => {
      return breakpoints.desktops ? (
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.X3Large} />
      ) : (
        <Spacer orientation={Spacer.Orientation.Horizontal} size={Spacer.Size.Large} />
      )
    }

    return (
      <div className="u-flexbox">
        <Spacer size={Spacer.Size.Large} orientation={Spacer.Orientation.Vertical} />
        <div className={containerClass}>
          <SeparatedList separator={renderSeparator()}>
            {sellerBadgesList.map((badge, index) => (
              <SellerBadge type={badge.type} key={index} />
            ))}
          </SeparatedList>
        </div>
      </div>
    )
  }

  return (
    <div className="u-flexbox">
      <div>
        <Image src={photoUrl} styling={Image.Styling.Circle} size={Image.Size.X4Large} alt="" />
      </div>
      <Spacer size={Spacer.Size.Large} orientation={Spacer.Orientation.Vertical} />
      <div className="u-flex-grow">
        <Cell
          title={
            <UserTitle
              businessAccountName={user.business_account?.name}
              login={user.login}
              isBusiness={user.business}
            />
          }
          body={renderHeaderContent()}
          suffix={renderHeaderSuffix()}
        />
        {renderSellerBadges()}
        <UserStatuses
          onUnblockClick={isProfileBlockingImprovementsEnabled ? handleBlockClick : undefined}
          user={user}
          screen={screen}
          showSupportFunctionality={showSupportFunctionality}
          isShadowBanned={isShadowBanned}
          isPermanentlyBanned={isPermanentlyBanned}
          isTemporarilyBanned={isTemporarilyBanned}
          isCompromised={isCompromised}
          isListingRestricted={isListingRestricted}
          viewingSelf={viewingSelf}
        />
        <Cell>
          <UserInfo
            user={user}
            showSupportFunctionality={showSupportFunctionality}
            canSendTemplates={canSendTemplates}
            onTogglePoliciesModal={onTogglePoliciesModal}
            viewingSelf={viewingSelf}
          />
        </Cell>
        <UserDescription
          user={user}
          isContentTranslationEnabled={isContentTranslationEnabled}
          viewingSelf={viewingSelf}
        />
      </div>
      <UnauthenticatedReportModal
        isVisible={isReportModalVisible}
        onClose={handleReportModalClose}
      />
      {renderUserBlockModal()}
    </div>
  )
}

export default InfoHeader
