'use client'

import { useIntl } from 'react-intl'
import { InfoBanner, Spacer } from '@vinted/web-ui'

import { LISTING_RESTRICTION_URL } from 'constants/routes'
import { useTranslate } from '@marketplace-web/shared/i18n'

type Props = {
  listingRestrictedUntil?: string | null
}

const ListingRestrictionBanner = ({ listingRestrictedUntil }: Props) => {
  const translate = useTranslate('listing_restriction.info_banner')

  const { formatDate } = useIntl()

  const getFormattedDate = () => {
    if (!listingRestrictedUntil) return undefined

    const date = new Date(listingRestrictedUntil)

    const formattedDate = formatDate(date, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })

    return formattedDate.replace(/-/g, ' ')
  }

  const formattedDate = getFormattedDate()

  if (!listingRestrictedUntil) return null

  return (
    <>
      <InfoBanner
        type={InfoBanner.Type.Warning}
        title={translate('title', {
          date: formattedDate,
        })}
        body={translate('description', {
          date: formattedDate,
          'link-learn-more': chunks => <a href={LISTING_RESTRICTION_URL}>{chunks}</a>,
        })}
      />
      <Spacer />
    </>
  )
}

export default ListingRestrictionBanner
