'use client'

import { ArrowLeft24 } from '@vinted/monochrome-icons'
import { Button, Cell, Dialog, Navigation, Text } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { ScrollableArea } from '@marketplace-web/shared/ui-helpers'

type Props = {
  show: boolean
  onBack: () => void
}

const ClosetPromotionDynamicPricingInfoModal = ({ show, onBack }: Props) => {
  const translate = useTranslate('closet_promotion.dynamic_pricing_info_modal')
  const a11yTranslate = useTranslate('closet_promotion.a11y.actions')

  function renderInfoBlock(type: string) {
    return (
      <Cell
        title={<Text as="h2" text={translate(`${type}.title`)} type={Text.Type.Title} />}
        body={<Text as="span" text={translate(`${type}.body`)} html />}
      />
    )
  }

  return (
    <Dialog show={show} hasScrollableContent>
      <div className="u-fill-width">
        <ScrollableArea>
          <Navigation
            left={
              <Button
                theme="amplified"
                iconName={ArrowLeft24}
                styling={Button.Styling.Flat}
                testId="closet-promotion-dynamic-pricing-info-back-button"
                onClick={onBack}
                aria={{ 'aria-label': a11yTranslate('back') }}
              />
            }
            body={<Text as="h2" text={translate('title')} type={Text.Type.Title} />}
          />
          {renderInfoBlock('what')}
          {renderInfoBlock('how')}
        </ScrollableArea>
      </div>
    </Dialog>
  )
}

export default ClosetPromotionDynamicPricingInfoModal
