'use client'

import { useEffect } from 'react'
import { Dialog, Spacer, Text, Button, Badge, Icon } from '@vinted/web-ui'
import { Gift96 } from '@vinted/multichrome-icons'
import { Clock16 } from '@vinted/monochrome-icons'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import useFetch from 'hooks/useFetch'

import { clickEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ITEM_UPLOAD_URL } from 'constants/routes'
import { Screen } from 'constants/tracking/screens'
import { getBumpsNewMemberItemUploadMotivation } from 'data/api'
import { transformBumpsNewMemberItemUploadMotivationResponse } from 'data/api/transformers/response'

import { ContentLoader, ErrorState } from '@marketplace-web/shared/ui-helpers'

type Props = {
  showDialog: boolean
  onClose: () => void
}

const BumpGiftModal = ({ showDialog, onClose }: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('bump_items.a11y.actions')

  const {
    fetch: fetchBumpMotivation,
    transformedData: bumpMotivationData,
    isLoading,
    error,
  } = useFetch(
    getBumpsNewMemberItemUploadMotivation,
    transformBumpsNewMemberItemUploadMotivationResponse,
  )

  useEffect(() => {
    if (!showDialog) return
    if (bumpMotivationData) return

    fetchBumpMotivation()
  }, [showDialog, bumpMotivationData, fetchBumpMotivation])

  const handleButtonClick = () => {
    track(
      clickEvent({
        target: ClickableElement.UploadItem,
        screen: Screen.BumpWelcomeGift,
      }),
    )
  }

  if (error)
    return (
      <Dialog show={showDialog}>
        <ErrorState ctaText={translate('close')} onCtaClick={onClose} />
      </Dialog>
    )

  if (isLoading || !bumpMotivationData)
    return (
      <Dialog show={showDialog}>
        <ContentLoader
          size={ContentLoader.Size.XLarge}
          styling={ContentLoader.Styling.Wide}
          testId="bump-gift-modal-loader"
        />
      </Dialog>
    )

  const { title, subtitle, expirationWarning, ctaTitle } = bumpMotivationData

  return (
    <Dialog
      show={showDialog}
      closeOnOverlay
      enableCloseButton
      defaultCallback={onClose}
      testId="bump-gift-modal"
      iconName={Gift96}
      title={title}
      a11yCloseIconTitle={translate('close')}
      actions={[
        {
          style: Button.Styling.Filled,
          text: ctaTitle,
          callback: handleButtonClick,
          url: ITEM_UPLOAD_URL,
          testId: 'bump-gift-modal-sell-now-button',
        },
      ]}
      body={
        <div className="u-flexbox u-flex-direction-column u-align-items-center">
          <Text as="span" text={subtitle} type={Text.Type.Body} alignment={Text.Alignment.Center} />
          <Spacer size={Spacer.Size.XLarge} />
          <Badge
            icon={<Icon name={Clock16} aria={{ 'aria-hidden': 'true' }} />}
            content={expirationWarning}
            theme="warning"
            styling={Badge.Styling.Light}
          />
        </div>
      }
    />
  )
}

export default BumpGiftModal
