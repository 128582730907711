'use client'

import { useState } from 'react'
import { Clock16 } from '@vinted/monochrome-icons'
import { Gift48 } from '@vinted/multichrome-icons'
import { Box as BoxExperimental } from '@vinted/playground'
import { Badge, Cell, Icon, Spacer, Text } from '@vinted/web-ui'

import { useTracking } from '@marketplace-web/shared/event-tracker'

import { clickClaimYourGiftEvent } from '_libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'
import { VasEntryPointModel } from 'types/models'

import BumpGiftModal from '../BumpGiftModal'

type Props = {
  bumpGiftBanner?: VasEntryPointModel
  isOtherVasBannerVisible: boolean
}

const BumpGiftBanner = ({ bumpGiftBanner, isOtherVasBannerVisible }: Props) => {
  const { track } = useTracking()
  const [isOpen, setIsOpen] = useState(false)

  const handleModalOpen = () => {
    setIsOpen(true)

    track(
      clickClaimYourGiftEvent({
        screen: Screen.CurrentUserProfile,
      }),
    )
  }

  const handleModalClose = () => {
    setIsOpen(false)
  }

  if (!bumpGiftBanner) return null

  const { title, subtitle, badgeTitle } = bumpGiftBanner

  return (
    <>
      <BoxExperimental theme="primary-extra-light" border="none" testId="bump-gift-banner">
        <Cell
          title={title}
          prefix={<Icon name={Gift48} />}
          theme="transparent"
          type={Cell.Type.Navigating}
          onClick={handleModalOpen}
        >
          <Text
            as="h3"
            text={subtitle}
            type={Text.Type.Subtitle}
            width={Text.Width.Parent}
            theme="amplified"
          />
          <Spacer size={Spacer.Size.Regular} />
          <Badge
            icon={<Icon name={Clock16} aria={{ 'aria-hidden': 'true' }} />}
            content={badgeTitle}
            theme="primary"
            styling={Badge.Styling.Light}
          />
        </Cell>
      </BoxExperimental>
      {isOtherVasBannerVisible && <Spacer size={Spacer.Size.Medium} />}
      <BumpGiftModal showDialog={isOpen} onClose={handleModalClose} />
    </>
  )
}

export default BumpGiftBanner
