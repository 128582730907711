'use client'

import { noop } from 'lodash'
import { Button, Card, Cell, Divider, Icon, Image, Rating, Spacer } from '@vinted/web-ui'
import { Pencil24 } from '@vinted/monochrome-icons'

import { ProfileBadges } from '@marketplace-web/domain/seller-engagement-tools'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { scrollToElementById } from '@marketplace-web/shared/browser'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { SeparatedList, UserTitle } from '@marketplace-web/shared/ui-helpers'

import { EMPTY_USER_IMAGE_NAME } from 'constants/images'
import { PROFILE_SETTINGS_URL } from 'constants/routes'
import { Screen } from 'constants/tracking/screens'
import { calculateRating } from 'data/utils/user'
import useAsset from 'hooks/useAsset'
import { UserDto } from 'types/dtos'

import UserInfo from '../InfoHeader/UserInfo'
import UserDescription from '../InfoHeader/UserDescription'
import UserStatuses from '../InfoHeader/UserStatuses'
import { useGetHasUserStatuses } from '../../../hooks/useGetHasUserStatuses'

const MAX_RATING = 5

type Props = {
  user: UserDto
  showSupportFunctionality: boolean
  canSendTemplates: boolean
  screen: Screen
  isShadowBanned?: boolean
  isPermanentlyBanned?: boolean
  isTemporarilyBanned?: boolean
  isCompromised?: boolean
  isContentTranslationEnabled: boolean
  onTogglePoliciesModal?: () => void
  onRatingClick?: () => void
}

const UserInfoPanel = ({
  user,
  showSupportFunctionality,
  canSendTemplates,
  screen,
  isShadowBanned,
  isPermanentlyBanned,
  isTemporarilyBanned,
  isCompromised,
  isContentTranslationEnabled,
  onTogglePoliciesModal = noop,
  onRatingClick = noop,
}: Props) => {
  const breakpoints = useBreakpoint()
  const translate = useTranslate('user')
  const asset = useAsset('assets/no-photo')
  const hasStatuses = useGetHasUserStatuses(user)

  if (breakpoints.phones) return null

  const photoUrl = user.photo?.url || asset(EMPTY_USER_IMAGE_NAME)
  const rating = calculateRating(user.feedback_reputation)

  function handleRatingsClick() {
    onRatingClick()
    scrollToElementById('tabs')
  }

  function renderEditProfileAction() {
    return (
      <Button
        icon={<Icon name={Pencil24} color={Icon.Color.GreyscaleLevel2} />}
        url={PROFILE_SETTINGS_URL}
        text={translate('action.edit_profile')}
        theme="muted"
        size={Button.Size.Medium}
      />
    )
  }

  function renderHeaderContent() {
    return (
      <div>
        <button type="button" onClick={handleRatingsClick} data-testid="rating-button">
          <Rating
            aria={{
              'aria-label':
                (rating && translate('a11y.rating', { rating, max_rating: MAX_RATING })) || '',
            }}
            value={rating}
            text={translate(
              'feedback.reviews',
              { count: user.feedback_count },
              { count: user.feedback_count },
            )}
            emptyStateText={translate('feedback.no_reviews')}
          />
        </button>
      </div>
    )
  }

  return (
    <div className="sticky-panel__sticky-with-tabs">
      <Card>
        <div
          className="u-flexbox u-flex-direction-column u-padding-large profile__user-info-panel"
          data-testid="user-info-panel"
        >
          <div>
            <Image src={photoUrl} styling={Image.Styling.Circle} size={Image.Size.X3Large} alt="" />
          </div>
          <div className="u-flex-grow u-padding-top-medium">
            <SeparatedList
              separator={
                <>
                  <Spacer size={Spacer.Size.Large} />
                  <Divider />
                  <Spacer size={Spacer.Size.Large} />
                </>
              }
            >
              <Cell
                title={
                  <UserTitle
                    businessAccountName={user.business_account?.name}
                    login={user.login}
                    isBusiness={user.business}
                  />
                }
                body={renderHeaderContent()}
                styling={Cell.Styling.Tight}
              />
              {hasStatuses && (
                <UserStatuses
                  user={user}
                  screen={screen}
                  showSupportFunctionality={showSupportFunctionality}
                  isShadowBanned={isShadowBanned}
                  isPermanentlyBanned={isPermanentlyBanned}
                  isTemporarilyBanned={isTemporarilyBanned}
                  isCompromised={isCompromised}
                  viewingSelf
                />
              )}
              <UserInfo
                user={user}
                showSupportFunctionality={showSupportFunctionality}
                canSendTemplates={canSendTemplates}
                onTogglePoliciesModal={onTogglePoliciesModal}
                viewingSelf
              />
              <div>
                {user.about && (
                  <>
                    <UserDescription
                      viewingSelf
                      user={user}
                      isContentTranslationEnabled={isContentTranslationEnabled}
                    />
                    <Spacer size={Spacer.Size.Large} />
                  </>
                )}
                {renderEditProfileAction()}
              </div>
            </SeparatedList>
          </div>
        </div>
      </Card>
      <div className="profile__user-info-panel__badges">
        <ProfileBadges />
      </div>
    </div>
  )
}

export default UserInfoPanel
